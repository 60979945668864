import { Button } from "@3beehivetech/components";
import React from "react";
import useNavigate from "../../hooks/useNavigate";
import { 
  StyledInfoBoxCard,
  StyledInfoBoxContainer,
  StyledInfoBoxCta,
  StyledInfoBoxIcon,
  StyledInfoBoxIconContainer,
  StyledInfoBoxItem,
  StyledInfoBoxItemContent,
  StyledInfoBoxItemIcon,
  StyledInfoBoxItemsContainer,
  StyledInfoBoxItemTitle,
  StyledInfoBoxTitle,
} from "./style";

const InfoBoxItem = ({
  image,
  text,
  title,
}) => {
  return (
    <StyledInfoBoxItem>
      <div>
        <StyledInfoBoxItemIcon src={image} loading="lazy" />
      </div>
      <StyledInfoBoxItemTitle><p dangerouslySetInnerHTML={{ __html: title }} /></StyledInfoBoxItemTitle>
      <StyledInfoBoxItemContent>{text}</StyledInfoBoxItemContent>
    </StyledInfoBoxItem>
  );
};

const InfoBox = ({
  id,
  title,
  icon,
  items,
  variant,
  ctaLabel,
  to = "/trees",
}) => {
  return (
    <StyledInfoBoxContainer id={id}>
      <StyledInfoBoxIconContainer>
        <StyledInfoBoxIcon src={icon} loading="lazy" />
      </StyledInfoBoxIconContainer>
      <StyledInfoBoxCard variant={variant}>
        <StyledInfoBoxTitle variant={variant} >{title}</StyledInfoBoxTitle>
        <StyledInfoBoxItemsContainer>
          {items.map((item, index) => (
            <InfoBoxItem key={index} image={item.image} title={item.title} text={item.text}/>
          ))}
        </StyledInfoBoxItemsContainer>
      </StyledInfoBoxCard>
      <StyledInfoBoxCta>
        <Button
          onClick={useNavigate(to)}
          variant={variant}
        >
          {ctaLabel}
        </Button>
      </StyledInfoBoxCta>
    </StyledInfoBoxContainer>
  );
};

export default InfoBox;
