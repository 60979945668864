import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

const variantColors = {
  orange: {
    light: Colors.ligthyellow,
    primary: Colors.yellow,
  },
  pink: {
    light: Colors.pink,
    primary: Colors.pinkText,
  },
  green: {
    light: Colors.greenlight,
    primary: Colors.greenPrimary,
  },
};

export const StyledInfoBoxContainer = styled.div`
  position: relative;

  @media (${BP.tablet}) {
    margin: 0 ${Rem(100)};
  }
`;

export const StyledInfoBoxCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Rem(42)} 0;
  background-color: ${(props) => variantColors[props.variant].light};
  
  @media (${BP.tablet}) {
    padding: ${Rem(42)};
    border-radius: ${Rem(30)};
  }
`;

export const StyledInfoBoxTitle = styled.div`
  text-align: center;
  font-size: ${Rem(32)};
  margin-bottom: ${Rem(25)};

  color: ${(props) => variantColors[props.variant].primary};
  @media (${BP.tablet}) {
    margin-bottom: ${Rem(50)};
  }
`;

export const StyledInfoBoxIconContainer = styled.div`
  position: absolute;
  padding-left: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  top: -${Rem(44)};
`;

export const StyledInfoBoxIcon = styled.img`
  height: ${Rem(88)};
`;

export const StyledInfoBoxCta = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  bottom: -${Rem(15)};
`;

export const StyledInfoBoxItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${BP.tablet}) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const StyledInfoBoxItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 180px;
  margin-bottom: ${Rem(20)};
  align-items: center;
  
  @media (${BP.tablet}) {
    margin-bottom: 0;
  }
`;

export const StyledInfoBoxItemIcon = styled.img`
  height: ${Rem(88)};
`;

export const StyledInfoBoxItemTitle = styled.div`
  color: ${Colors.black};
  padding: ${Rem(30)} 0;
  font-weight: bold;
  font-size: 1.1rem;
`;

export const StyledInfoBoxItemContent = styled.div`
  color: ${Colors.black};
  font-weight: 100;
  font-size: 1rem;
`;
